import { Snippet } from '../types/conversation';

/**
 * Returns true if the seek time is within the snippet range.
 */
export function seekInSnippet(
  seekTime: number | undefined,
  snippet: { audio_start_offset: number; audio_end_offset: number },
  /** if true, modify the result to be true if almost in the snippet */
  approximate: boolean = false
): boolean {
  // if within 0.1s of the start or end time, count it if using approximate
  const epsilon = approximate ? 0.1 : 0;
  return (
    seekTime != null &&
    snippet.audio_start_offset - epsilon <= seekTime &&
    seekTime < snippet.audio_end_offset + epsilon
  );
}

export function getActiveSnippet(
  seekTime: number | undefined,
  snippets: Snippet[],
  startTime: number
): Snippet | undefined {
  if (!snippets.length) {
    return undefined;
  }
  let activeSnippet = snippets.find(snippet =>
    seekInSnippet(seekTime, snippet)
  );

  // if we don't have any snippet in the seek time,
  // choose based on the passed in seek time (first or last)
  if (!activeSnippet) {
    if (seekTime == null || seekTime < startTime) {
      activeSnippet = snippets[0];
    } else {
      activeSnippet = snippets[snippets.length - 1];
    }
  }
  return activeSnippet;
}
