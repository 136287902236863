import React from 'react';
import styles from './Button.module.scss';

interface Props {
  className?: string;
  color?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void | undefined;
  onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void | undefined;
  onBlur?: (e: React.SyntheticEvent<any>) => void | undefined;
  multiline?: boolean;
}
const Button = ({ className, color, children, multiline, ...other }: Props) => {
  return (
    <button
      className={`${className ? className : ''} ${styles.button} ${
        multiline ? styles.buttonMultiline : ''
      }`}
      {...other}
    >
      {children}
    </button>
  );
};

export default Button;
