export enum Category {
  Embed = 'Embed',
  HighlightCard = 'Highlight Card',
}

export enum Action {
  Click = 'Click',
  Download = 'Download',
  Exit = 'Exit',
  Pause = 'Pause',
  Play = 'Play',
  Seek = 'Seek',
  Share = 'Share',
  Stop = 'Stop',
}

export enum Name {
  Highlight = 'Highlight',
  PlayPauseButton = 'PlayPause Button',
  SignUp = 'Sign Up',
  Share = 'Share',
  TranscriptRoll = 'Transcript Roll',
  WhatIsThis = 'What is this?',
}

  export interface AnalyticsEvent {
  category: Category;
  action: Action;
  name: Name;
}
