export function linearClampedScale(
  /**
   * Minimal replacement for d3.scaleLinear with clamp
   */

  num: number,
  domain: [number, number],
  range: [number, number]
) {
  const domainProportion = (num - domain[0]) / (domain[1] - domain[0]);
  const rangeProportion = domainProportion * (range[1] - range[0]);
  return Math.min(range[1], Math.max(range[0], rangeProportion + range[0]));
}
