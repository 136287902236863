import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import {
  polyfillObjectFind,
  polyfillStringRepeat,
  polyfillObjectAssign,
  polyfillArrayFrom,
} from './util/polyfill';

polyfillObjectFind();
polyfillStringRepeat();
polyfillObjectAssign();
polyfillArrayFrom();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if ((module as any).hot) {
  (module as any).hot.accept('./components/App/App', () => {
    const NextApp = require('./components/App/App').default;
    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}
