import React from 'react';
import { Snippet, WordEntry } from '../../types/conversation';

import styles from './TranscriptRoll.module.scss';
import { useAnalyticsContext } from '../AnalyticsProvider/AnalyticsProvider';
import { Action, Category, Name } from '../../types/analytics';

interface Props {
  seekTime: number | undefined;
  snippets: Snippet[];
  activeSnippet: Snippet | undefined;
  startTime: number;
  endTime: number;
  windowWidth: number;
  fadeInTime: number;
  audioIsLoaded: boolean;
  className?: string | undefined;
  onSeek?: (seekTime: number) => void;
}

const TranscriptRoll = ({
  seekTime,
  snippets,
  activeSnippet,
  startTime,
  endTime,
  windowWidth,
  className,
  fadeInTime,
  onSeek,
  audioIsLoaded,
}: Props) => {
  // Not currently used
  // how many seconds we should pre-emptively set the word to active
  // to allow the animation to run before the word is said
  // const activeWordEnterTime = 0.0;

  // Not currently used
  // minimum amount a time a word should take to be said
  // (it will be highlighted minimally for this duration)
  // const minWordTime = 0.0;

  // get word bboxes to move the highlight box through
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [wordBboxes, setWordBboxes] = React.useState<DOMRect[]>([]);
  const { analyticsEvent } = useAnalyticsContext();

  React.useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    const rootBbox = ref.current.getBoundingClientRect() as DOMRect;
    const wordNodes = Array.from(ref.current.querySelectorAll('.word'));
    const firstWordRelativeLocation =
      wordNodes[0].getBoundingClientRect().top - rootBbox.top;
    const bboxes = wordNodes.map((node) => {
      const bbox = node.getBoundingClientRect() as DOMRect;

      return {
        x: bbox.left - rootBbox.left,
        y: bbox.top - rootBbox.top - firstWordRelativeLocation,
        width: bbox.width,
        height: bbox.height,
      };
    }) as DOMRect[];
    setWordBboxes(bboxes);
  }, [snippets, windowWidth, audioIsLoaded]);

  if (!snippets || !activeSnippet) {
    return null;
  }

  // find the active word and snippet based on last word that starts before seek time
  let activeWordIndex = 0;
  let wordCount = 0;
  // number of words that are in the snippet but are before highlight audio start
  // we need to keep track of this in order to get an accurate word index relative to
  // the number of words actually in the highlight
  let skippedWords = 0;
  for (let i = 0; i < snippets.length; ++i) {
    for (let j = 0; seekTime != null && j < snippets[i].words.length; ++j) {
      const word = snippets[i].words[j];
      if (startTime < word[2] && endTime > word[1]) {
        if (snippets[i].words[j][1] > seekTime) {
          break;
        }
        activeWordIndex = j + wordCount - skippedWords;
      } else {
        skippedWords++;
      }
    }
    wordCount += snippets[i].words.length;
  }

  // get a list of the y positions of each line
  const linesY = wordBboxes
    .map((bbox) => bbox.y)
    .filter((value, index, array) => array.indexOf(value) === index);

  // update the position of the active word box
  const activeWordBbox = wordBboxes[activeWordIndex];
  const seekHighlightBarStyle: any = {};
  let topLineY = 0;

  if (activeWordBbox) {
    // get the position that the div should be moved up based on the line number
    const activeLineIndex = linesY.indexOf(activeWordBbox.y);
    let numVisibleRows = 2;
    const lineHeight = 1.95; // from css
    if (ref.current) {
      const containerHeight = ref.current.getBoundingClientRect().height;
      numVisibleRows = Math.ceil(
        containerHeight / wordBboxes[0].height / lineHeight
      );
    }

    topLineY = -linesY[activeLineIndex - (activeLineIndex % numVisibleRows)];

    // only fade in if we are playing
    if (seekTime != null) {
      seekHighlightBarStyle.opacity = 1;
    }
    // need to set position even if not playing so it is initialized properly
    seekHighlightBarStyle.transform = `translate(${activeWordBbox.x}px, ${activeWordBbox.y}px)`;
    seekHighlightBarStyle.width = `${activeWordBbox.width}px`;
  }

  const handleWordClicked = (word: WordEntry) => {
    // use an epsilon in order to make sure we click the right word in the case of
    // close word boundaries
    if (audioIsLoaded && onSeek) {
      const epsilon = 0.000001;
      const seekTime = word[1] - startTime + fadeInTime + epsilon;

      analyticsEvent({
        category: Category.Embed,
        action: Action.Seek,
        name: Name.TranscriptRoll,
      });
      onSeek(seekTime);
    }
  };

  return (
    <div
      ref={ref}
      className={`${className ? className : ''} ${styles.container}`}
    >
      <div
        className={styles.words}
        style={{ transform: `translateY(${topLineY}px)` }}
      >
        <div /* box that moves along with the currently active word */
          className={styles.seekHighlightBar}
          style={seekHighlightBarStyle}
        />
        <div className={styles.wordsInner}>
          <div>
            {snippets.map((snippet, i) => {
              let snippetStyle = `${styles.snippet}`;
              // grey out any words not spoken by the current speaker
              if (activeSnippet.speaker_id !== snippet.speaker_id) {
                snippetStyle = `${styles.snippet} ${styles.inactiveSnippet}`;
              }
              return (
                <div className={snippetStyle} key={i}>
                  {snippet.words
                    .filter((word) => startTime < word[2] && endTime > word[1])
                    .map((word, j) => {
                      return (
                        <React.Fragment key={j}>
                          <span
                            className={`word ${styles.word} ${
                              audioIsLoaded ? styles.clickableWord : ''
                            }`}
                            onClick={() => handleWordClicked(word)}
                          >
                            {word[0]}
                          </span>{' '}
                        </React.Fragment>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TranscriptRoll);
