import React from 'react';

import { getFullAudioUrl } from '../../api/api';
import GlobalAudioContext from '../../contexts/GlobalAudioContext';
import { Snippet } from '../../types/conversation';
import { getQueryParam } from '../../util/url';
import AudioControls from '../AudioControls/AudioControls';
import TranscriptRoll from '../TranscriptRoll/TranscriptRoll';
import styles from './MinimalCard.module.scss';

interface Props {
  audioUrl: string;
  seekTime: number | undefined;
  snippets: Snippet[];
  activeSnippet: Snippet | undefined;
  startTime: number;
  endTime: number;
  windowWidth: number;
  fadeInTime: number;
  onSeek?: (seekTime: number) => void;
}
const MinimalCard = ({
  audioUrl,
  seekTime,
  snippets,
  activeSnippet,
  startTime,
  endTime,
  windowWidth,
  fadeInTime,
  onSeek,
}: Props) => {
  const {
    src,
    onChangeSound,
    isLoaded,
    audioError,
    isLoading,
    duration,
    seekTime: controlSeekTime,
    onSeek: controlSeek,
    setCurrentSrc,
  } = React.useContext(GlobalAudioContext);

  const fontSize = React.useMemo(() => getQueryParam('fsz'), []);
  const fontWeight = React.useMemo(() => getQueryParam('fwt'), []);
  const fontStyle = React.useMemo(() => getQueryParam('ital'), []);
  const fontFamily = React.useMemo(() => getQueryParam('ffm'), []);
  const highlightColor = React.useMemo(() => getQueryParam('hic'), []);
  const textColor = React.useMemo(() => getQueryParam('txc'), []);
  const backgroundColor = React.useMemo(() => getQueryParam('bgc'), []);
  const scrolling = React.useMemo(() => getQueryParam('scroll'), []);
  const audioControl = React.useMemo(() => getQueryParam('act'), []);

  React.useEffect(() => {
    // Set src of audio in context on page load.
    // This allows for src to be set on audio when audio play is requested.
    const fullAudioUrl = getFullAudioUrl(audioUrl, false);
    if (src !== fullAudioUrl) {
      setCurrentSrc(fullAudioUrl);
    } else if (audioError) {
      // in the case of an audio error, avoid using the browser's cached version of the audio file
      // https://stackoverflow.com/questions/25821915/how-to-force-the-html5-audio-tag-to-reload-a-changing-file
      onChangeSound(getFullAudioUrl(audioUrl, true));
    }
  }, [audioError, audioUrl, onChangeSound, src, setCurrentSrc]);

  const fallbackDuration = endTime - startTime + fadeInTime * 2;
  return (
    <div
      data-testid="highlight-card"
      style={{ backgroundColor: backgroundColor ? backgroundColor : 'inherit' }}
    >
      <style scoped>
        {`/* This is not our usual way to define a style, however, since we want the style definition to be based on
          query parameters
          */
          ${
            highlightColor
              ? `[class*="TranscriptRoll_seekHighlightBar"]{
                  background-color: ${highlightColor} !important;
              }`
              : ''
          }
          ${
            backgroundColor
              ? `[class*="TranscriptRoll_container"]{
                  background-color: ${backgroundColor} !important;
              }`
              : ''
          }
          ${
            scrolling
              ? `[class*="TranscriptRoll_container"]{
                  overflow: auto !important;
              }`
              : ''
          }
          ${fontWeight ? `span{font-weight: ${fontWeight};}` : ''}
          ${fontSize ? `span{font-size: ${fontSize};}` : ''}
          ${textColor ? `span{color: ${textColor};}` : ''}
          ${fontFamily ? `span{font-family: '${fontFamily}', sans-serif;}` : ''}
          ${fontStyle ? `span{font-style: italic;}` : ''}
          `}
      </style>
      {!!audioControl && (
        <AudioControls
          className={styles.audioControls}
          showPlayControls={false}
          isLoading={isLoading}
          isLoaded={isLoaded && audioError == null}
          seekTime={controlSeekTime}
          duration={
            duration === Number.POSITIVE_INFINITY ? fallbackDuration : duration
          }
          onSeek={controlSeek}
        />
      )}
      <TranscriptRoll
        seekTime={seekTime}
        snippets={snippets}
        activeSnippet={activeSnippet}
        startTime={startTime}
        endTime={endTime}
        windowWidth={windowWidth}
        onSeek={onSeek}
        fadeInTime={fadeInTime}
        audioIsLoaded={isLoaded && audioError == null}
        className={styles.wrapper}
      />
    </div>
  );
};

export default MinimalCard;
