import React from 'react';

import ButtonLink from '../Button/ButtonLink';
import CloseButton from '../CloseButton/CloseButton';

import styles from './WhatIsThisView.module.scss';
import ForaLogo from '../ForaLogo/ForaLogo';

interface Props {
  organization?: string;
  collection?: string;
  onExit: () => void;
  small: boolean;
}
const WhatIsThisView = ({ organization, collection, onExit, small }: Props) => {
  return (
    <div className={`${styles.container} ${small ? styles.small : ''}`}>
      <div className={styles.leftPanel}>
        {small ? <ForaLogo width={80} /> : <ForaLogo width={109} />}
        <ButtonLink multiline href="https://cortico.ai">
          Learn More
        </ButtonLink>
        <a
          href="https://cortico.ai/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.privacyPolicy}
        >
          Privacy Policy
        </a>
      </div>
      <div className={styles.textBody}>
        <p>
          Cortico's Fora brings people together in recorded small-group
          conversations around their lived experiences. Through a powerful
          combination of AI and human listening, Fora enables organizations to
          make sense of the conversations they collect.{' '}
          {organization && (
            <span>
              You're listening to an audio excerpt from our partnership with{' '}
              <strong>{organization}</strong>.{' '}
            </span>
          )}
          Learn more at{' '}
          <a
            href="https://cortico.ai/platform"
            target="_blank"
            rel="noopener noreferrer"
          >
            cortico.ai/platform
          </a>
          .
        </p>
      </div>
      <CloseButton
        className={styles.exit}
        onClick={onExit}
        data-testid="exit"
      />
    </div>
  );
};

export default WhatIsThisView;
