import React from 'react';
import ReactDOM from 'react-dom';
import { linearClampedScale } from '../../util/scale';
import { formatTime } from '../../util/format';

import './AudioControls.scss';

interface Props {
  isLoading?: boolean;
  isLoaded?: boolean;
  seekTime?: number;
  duration?: number;
  onSeek: (seekTime: number) => void;
  showPlayControls?: boolean;
  className?: string | undefined;
}

interface State {
  dragging: boolean;
  tooltipX: number;
  tooltipY: number;
  seekPreviewTime: number;
}

class AudioControls extends React.PureComponent<Props, State> {
  state: State = {
    dragging: false,
    seekPreviewTime: 0,
    tooltipX: -10000,
    tooltipY: -10000,
  };

  durationBar: React.RefObject<HTMLDivElement> | undefined = React.createRef();
  tooltipRef: React.RefObject<HTMLDivElement> | undefined = React.createRef();

  handleStartSeekDrag = (evt: React.MouseEvent<any>) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: true,
    });
    document.body.addEventListener('mousemove', this.handleSeekDragMouseMove);
    document.body.addEventListener('mouseup', this.handleStopDrag);
    document.body.addEventListener('mouseleave', this.handleStopDrag);
  };

  handleSeekDragMouseMove = (evt: any) => {
    let xPosition = evt.clientX;
    if (evt.clientX == null) {
      // evt.touches is an array of length = number of fingers that are touching the screen
      xPosition = evt.touches[0].clientX;
    }
    this.seekByXPosition(xPosition);
  };

  handleStopDrag = (evt: any) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: false,
    });
    document.body.removeEventListener(
      'mousemove',
      this.handleSeekDragMouseMove
    );
    document.body.removeEventListener('mouseup', this.handleStopDrag);
    document.body.removeEventListener('mouseleave', this.handleStopDrag);
  };

  // handle seeking on mobile (this won't get called with the regular mouse event)
  // pretty much the same as the mouse events, except with different listeners
  handleTouchStart = (evt: React.TouchEvent<any>) => {
    if (evt.touches[0]) {
      this.seekByXPosition(evt.touches[0].clientX);
      this.setState({
        dragging: true,
      });
      document.body.addEventListener('touchmove', this.handleSeekDragMouseMove);
      document.body.addEventListener('touchend', this.handleStopTouchDrag);
    }
  };

  handleStopTouchDrag = (evt: any) => {
    if (evt.touches[0]) {
      this.seekByXPosition(evt.touches[0].clientX);
      this.setState({
        dragging: false,
      });
      document.body.removeEventListener(
        'touchmove',
        this.handleSeekDragMouseMove
      );
      document.body.removeEventListener('touchend', this.handleStopTouchDrag);
    }
  };

  seekByXPosition = (x: number) => {
    const { duration = 1, onSeek, isLoaded } = this.props;
    if (!this.durationBar || !this.durationBar.current || !isLoaded) {
      return;
    }
    const barRect = this.durationBar.current.getBoundingClientRect();

    const seekTime = linearClampedScale(
      x,
      [barRect.left, barRect.left + barRect.width],
      [0, duration]
    );

    // TODO - add for Matomo analytics
    // throttledGA({
    //   category: 'Embed',
    //   action: 'Audio seek',
    //   label: 'AudioControls',
    // });

    onSeek(seekTime);
  };

  handleSeekMouseMove = (evt: any) => {
    const { duration = 1, seekTime, isLoaded } = this.props;
    if (
      !isLoaded ||
      !this.durationBar ||
      !this.durationBar.current ||
      seekTime === undefined
    ) {
      return;
    }
    const { clientX: mouseX, clientY: mouseY } = evt;
    const barRect = this.durationBar.current.getBoundingClientRect();

    // get the relative mouse position
    const relativeMouseX = mouseX - barRect.left;

    // get the preview time to show
    const seekPreviewTime = linearClampedScale(
      relativeMouseX,
      [0, barRect.width],
      [0, duration]
    );

    // get where the tooltip should be
    if (this.tooltipRef && this.tooltipRef.current) {
      const tooltip = this.tooltipRef.current;
      const tooltipY = mouseY + 20;
      // handle if tooltip would show up outside of card
      const rightEdge =
        barRect.width - tooltip.getBoundingClientRect().width + barRect.left;
      const tooltipX = mouseX > rightEdge ? rightEdge : (mouseX as number);
      this.setState({ tooltipX, tooltipY });
    }

    this.setState({ seekPreviewTime });
  };

  handleSeekMouseLeave = () => {
    // reset the tooltip location
    this.setState({ tooltipY: -10000, tooltipX: -10000, seekPreviewTime: 0 });
  };

  getSeekBarWidth = (time?: number) => {
    const { duration } = this.props;
    return linearClampedScale(time || 0, [0, duration || 0], [0, 100]);
  };

  render() {
    const { isLoading, isLoaded, duration, seekTime, className } = this.props;
    const { seekPreviewTime, tooltipX, tooltipY } = this.state;
    const root = window.document.getElementById('root');
    const combinedClassName = `AudioControls ${
      isLoading ? 'loading' : 'loaded'
    } ${isLoaded ? '' : 'notLoaded'} ${className ? className : ''}`;
    let seekPreviewStartPixel = 0;
    let seekPreviewWidth = 0;
    if (
      duration !== undefined &&
      seekTime !== undefined &&
      this.durationBar &&
      this.durationBar.current &&
      seekPreviewTime > 0
    ) {
      const barRect = this.durationBar.current.getBoundingClientRect();
      // get the position of the elapsed time bar
      const seekBarWidthPercentage = this.getSeekBarWidth(seekTime) / 100;

      // find where the seek preview should start
      // (either where the mouse is, or at the end of the duration bar)
      seekPreviewStartPixel = linearClampedScale(
        seekPreviewTime,
        [0, duration],
        [0, barRect.width]
      );
      const seekBarRight = seekBarWidthPercentage * barRect.width;
      if (seekPreviewTime > seekTime) {
        seekPreviewStartPixel = seekBarRight;
      }

      // and get the width to make the preview bar
      seekPreviewWidth = linearClampedScale(
        Math.abs(seekPreviewTime - seekTime),
        [0, duration],
        [0, 100]
      );
    }

    return (
      <div className={combinedClassName}>
        <div className="play-status-bar">
          <div
            className="interaction-bar"
            onMouseDown={this.handleStartSeekDrag}
            onMouseMove={this.handleSeekMouseMove}
            onMouseLeave={this.handleSeekMouseLeave}
            onTouchStart={this.handleTouchStart} // enable seek on mobile
            onTouchEnd={this.handleStopTouchDrag}
            data-testid="interaction-bar"
          >
            <div
              className={'duration-bar'}
              ref={this.durationBar}
              data-testid="duration-bar"
            >
              <div
                className={'seek-preview-bar'}
                style={{
                  width: `${isLoading ? 0 : seekPreviewWidth}%`,
                  left: `${seekPreviewStartPixel}px`,
                }}
              />
              <div
                className={'seek-bar'}
                style={{
                  width: `${isLoading ? 0 : this.getSeekBarWidth(seekTime)}%`,
                }}
              />
            </div>
          </div>
        </div>
        {root &&
          ReactDOM.createPortal(
            <div
              className="tooltip"
              ref={this.tooltipRef}
              data-testid="tooltip"
              style={{
                left: `${tooltipX}px`,
                top: `${tooltipY}px`,
                opacity: tooltipX > 0 && tooltipY > 0 ? 1 : 0,
              }}
            >
              {formatTime(seekPreviewTime)}
            </div>,
            root
          )}
      </div>
    );
  }
}

export default AudioControls;
