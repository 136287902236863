import React from 'react';
import TimesIcon from '../TimesIcon/TimesIcon';
import StylelessButton from '../StylelessButton/StylelessButton';

import styles from './CloseButton.module.scss';

interface Props {
  onClick: () => void;
  className?: string;
}
const CloseButton = ({ onClick, className, ...other }: Props) => {
  return (
    <StylelessButton
      className={`${styles.button} ${className ? className : ''}`}
      onClick={onClick}
      {...other}
    >
      <TimesIcon />
    </StylelessButton>
  );
};

export default CloseButton;
