import 'promise-polyfill/lib/polyfill'; // import from lib to get transpiled polyfill code, not from src

import React from 'react';
import HighlightCard from '../HighlightCard/HighlightCard';
import IframeTest from '../IframeTest/IframeTest';
import { GlobalAudioContextProvider } from '../../contexts/GlobalAudioContext';
import IframeTestMinimal from '../IframeTest/IframeTestMinimal';
import IframePlayerjsTest from '../IframeTest/IframePlayerjsTest';
import { AnalyticsProvider } from '../AnalyticsProvider/AnalyticsProvider';

const App = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('/iframe available to test');
    if (
      window.location.pathname === '/iframe' ||
      window.location.pathname === '/iframe/'
    ) {
      const params = new URLSearchParams(window.location.search);
      const widthParam = params.get('w');
      const heightParam = params.get('h');
      const typeParam = params.get('type');
      const minimal = typeParam === 'minimal';
      const playerjs = !!params.get('playerjs');
      const width = widthParam ? parseInt(widthParam) : undefined;
      const height = heightParam ? parseInt(heightParam) : undefined;
      if (minimal) {
        return <IframeTestMinimal width={width} height={height} />;
      }
      if (playerjs) {
        return <IframePlayerjsTest />;
      }
      return <IframeTest width={width} height={height} />;
    }
  }

  return (
    <AnalyticsProvider>
      <GlobalAudioContextProvider>
        <HighlightCard />
      </GlobalAudioContextProvider>
    </AnalyticsProvider>
  );
};

export default App;
