import { PropsWithChildren } from 'react';
import React from 'react';

import { AnalyticsEvent } from '../../types/analytics';

// TYPES

type AnalyticsContextValues = {
  analyticsEvent: (props: AnalyticsEvent) => void;
};

// CONFIG

const AnalyticsContext = React.createContext<AnalyticsContextValues | null>(
  null
);

const disableTracking = process.env.NODE_ENV !== 'production';

// HOOKS

export const useAnalyticsContext = () => {
  const value = React.useContext(AnalyticsContext);
  if (!value) {
    throw new Error(
      'component must be wrapped with <AnalyticsContext.Provider>'
    );
  }
  return value;
};

// PROVIDER
export const AnalyticsProvider = (props: PropsWithChildren<any>) => {
  React.useEffect(() => {
    if (disableTracking) {
      return;
    }

    const paq = (window._paq = window._paq || []);

    paq.push(['setCookieDomain', '*.fora.io']);
    paq.push(['enableLinkTracking']);

    paq.push(['setTrackerUrl', 'https://fora.matomo.cloud/matomo.php']);
    paq.push(['setSiteId', 1]);
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    if (!s.parentNode) {
      return;
    }
    g.async = true;
    g.src = '//cdn.matomo.cloud/fora.matomo.cloud/matomo.js';
    s.parentNode.insertBefore(g, s);
  }, []);

  const analyticsEvent = ({ category, action, name }: AnalyticsEvent) => {
    if (disableTracking) {
      console.log('Analytics Event', { category, action, name });
      return;
    }

    const paq = (window._paq = window._paq || []);
    paq.push(['trackEvent', category, action, name]);
  };

  return (
    <AnalyticsContext.Provider value={{ analyticsEvent }}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};
