export function leftPad(num: number) {
  if (num < 10) {
    return `0${num}`;
  }

  return `${num}`;
}

export function formatTime(time: number) {
  const hours = Math.floor(time / (60 * 60));
  const minutes = Math.floor((time % (60 * 60)) / 60);
  const seconds = Math.floor(time % 60);

  const parts = [minutes, seconds];
  if (hours > 0) {
    parts.unshift(hours);
  }

  // don't leftpad biggest number
  return parts.map((d, i) => (i > 0 ? leftPad(d) : d)).join(':');
}

export const formatDate = (date: Date | undefined) => {
  if (!date) {
    return;
  }
  const options = { month: 'short', day: '2-digit', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};
