import React from 'react';

import LinkButton from '../StylelessButton/StylelessButton';
import styles from './NotFoundView.module.scss';
import ForaLogo from '../ForaLogo/ForaLogo';

interface Props {
  onWhatIsThisClick: () => void;
}
const NotFoundView = ({ onWhatIsThisClick }: Props) => {
  return (
    <div className={styles.container} data-testid="not-found-view">
      <div className={styles.content}>
        <ForaLogo width={109} />
        <div>Sorry, we were unable to find the requested highlight.</div>
      </div>
      <div className={styles.footer}>
        <LinkButton className={styles.clickable} onClick={onWhatIsThisClick}>
          What is this?
        </LinkButton>
      </div>
    </div>
  );
};

export default NotFoundView;
