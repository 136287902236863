import React from 'react';

import styles from './TimesIcon.module.scss';

interface Props {
  width?: number;
}
const TimesIcon = ({ width = 16 }: Props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={styles.timesIcon}
      width={width}
      height={width}
    >
      <line x1={0} x2="100%" y1={0} y2="100%" stroke="currentColor" />
      <line x2={0} x1="100%" y1={0} y2="100%" stroke="currentColor" />
    </svg>
  );
};

export default TimesIcon;
