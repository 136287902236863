import React from 'react';

import Button from '../Button/Button';
import CloseButton from '../CloseButton/CloseButton';

import styles from './SignUpView.module.scss';
import ButtonLink from '../Button/ButtonLink';
import ReplayIcon from '../ReplayIcon/ReplayIcon';
import ForaLogo from '../ForaLogo/ForaLogo';

interface Props {
  speaker: string;
  location: string;
  showPlayAgainButton: boolean;
  onReplay: () => void;
  onExit: () => void;
  small: boolean;
}
const SignUpView = ({
  speaker,
  location,
  showPlayAgainButton,
  onReplay,
  onExit,
  small,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.centeredContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.leftPanel}>
            <ForaLogo width={109} />
          </div>
          <div className={styles.textBody}>
            <span>
              Hear more local voices like <strong>{speaker}'s</strong> in{' '}
              {location} on Fora.
            </span>
            <div className={styles.buttonGroup}>
              <ButtonLink className={styles.button} href="https://fora.io">
                Learn More
              </ButtonLink>
              {showPlayAgainButton && (
                <Button
                  className={`${styles.button} ${styles.playAgain}`}
                  onClick={onReplay}
                >
                  <span className={styles.replayIcon}>
                    <ReplayIcon width={small ? 10 : 12} />
                  </span>
                  Play Again
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <CloseButton
        className={styles.exit}
        onClick={onExit}
        data-testid="exit"
      />
    </div>
  );
};

export default SignUpView;
