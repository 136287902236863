// polyfill fetch
import 'whatwg-fetch';
import { FeatureFlags } from '../types/core';

function fetchErrorCheck(response: any) {
  return (results: any) => {
    /** handle http status codes not being 200 OK and JSend error formats */
    if (response.status !== 200 || results.status === 'error') {
      const msg =
        results.error ||
        results.detail ||
        results.message ||
        JSON.stringify(results);
      throw Error(`FetchError: ${msg}`);
    }

    if (results.status === 'fail') {
      const msg =
        (results.data && results.data.error) ||
        results.detail ||
        results.message ||
        JSON.stringify(results);
      throw Error(`FetchFail: ${msg}`);
    }

    return results;
  };
}

export const getFeatureFlagStatus = (flag: FeatureFlags): boolean => {
  if (process.env.NODE_ENV === 'production') {
    return window.BOOTSTRAP_DATA.feature_flags
      ? window.BOOTSTRAP_DATA.feature_flags[flag]
      : false;
  } else {
    return false;
  }
};

export function urlWithQueryParams(
  url: string,
  query?: { [key: string]: string } | undefined | null
) {
  let urlFormatted;
  if (query) {
    const queryString = Object.keys(query)
      .filter(key => query[key] != null)
      .map(key => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');
    urlFormatted = `${url}?${queryString}`;
  } else {
    urlFormatted = url;
  }

  return urlFormatted;
}

/**
 * Helper for fetching GET requests
 */
export async function get(
  url: string,
  query?: { [key: string]: string } | undefined | null
): Promise<any> {
  return fetch(urlWithQueryParams(url, query), {
    method: 'GET',
    credentials: 'include',
  }).then(response => response.json().then(fetchErrorCheck(response)));
}
