import React from 'react';
import Button from '../Button/Button';
import CopyIcon from '../CopyIcon/CopyIcon';
import CheckIcon from '../CheckIcon/CheckIcon';

import styles from './CopyControl.module.scss';
interface Props {
  defaultText: string;
}
const CopyControl = ({ defaultText }: Props) => {
  const inputRef:
    | React.RefObject<HTMLInputElement>
    | undefined = React.createRef();
  const [text, setText] = React.useState(defaultText);
  const [isCopied, setIsCopied] = React.useState(false);
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    setText(value);
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const handleCopy = () => {
    // select the text to copy
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }

    setIsCopied(true);
  };

  const handleMouseLeave = () => {
    setIsCopied(false);
  };
  return (
    <div className={styles.copy}>
      <input
        type="text"
        value={text}
        onChange={handleChange}
        ref={inputRef}
        onFocus={handleFocus}
        className={styles.copyArea}
        readOnly={true}
      />
      <Button onClick={handleCopy} onMouseLeave={handleMouseLeave}>
        {isCopied ? <CheckIcon width={12} /> : <CopyIcon width={12} />}
      </Button>
    </div>
  );
};

export default CopyControl;
