import React from 'react';

import PlayPauseButton from '../PlayPauseButton/PlayPauseButton';
import StylelessButton from '../StylelessButton/StylelessButton';

import styles from './HighlightHeader.module.scss';
import ForaLogo from '../ForaLogo/ForaLogo';

interface Props {
  speaker: string;
  location: string;
  collection: string;
  audioUrl: string;
  onLogoClick: () => void;
  small: boolean;
}

const HighlightHeader = ({
  speaker,
  location,
  collection,
  audioUrl,
  onLogoClick,
  small,
}: Props) => {
  // get location to display
  let fullLocation = `${location}, ${collection}`;
  // avoid Unknown and things like Madison, Madison, WI
  if (location === 'Unknown' || location === collection.split(',')[0]) {
    fullLocation = collection;
  }

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.leftHeader}>
          <PlayPauseButton
            audioUrl={audioUrl}
            data-testid="play-pause-button"
          />
          <div className={styles.headerText}>
            <div className={styles.speaker}>{speaker}</div>
            <div className={styles.location}>{fullLocation}</div>
          </div>
        </div>
        <StylelessButton
          className={styles.logo}
          onClick={onLogoClick}
          data-testid="logo-button"
        >
          <ForaLogo width={109} />
        </StylelessButton>
      </div>
    </div>
  );
};

export default HighlightHeader;
