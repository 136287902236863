import React from 'react';
import styles from './Button.module.scss';

interface Props {
  className?: string;
  color?: string;
  children?: React.ReactNode;
  href?: string;
  target?: string;
  multiline?: boolean;
  onMouseLeave?: (e: React.MouseEvent<HTMLElement>) => void | undefined;
  onBlur?: (e: React.SyntheticEvent<any>) => void | undefined;
}
const ButtonLink = ({
  className,
  color,
  children,
  target = '_blank', // embed should always open links in new window
  href,
  multiline,
  ...other
}: Props) => {
  return (
    <a
      className={`${className ? className : ''} ${styles.button} ${
        multiline ? styles.buttonMultiline : ''
      }`}
      target={target}
      href={href}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      {...other}
    >
      {children}
    </a>
  );
};

export default ButtonLink;
