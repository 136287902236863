import React from 'react';

import { getFullAudioUrl } from '../../api/api';
import GlobalAudioContext from '../../contexts/GlobalAudioContext';
import SpinnerIcon from '../SpinnerIcon/SpinnerIcon';
import ExclamationIcon from '../ExclamationIcon/ExclamationIcon';
import styles from './PlayPauseButton.module.scss';
import { interpolateString } from 'd3-interpolate';
import { easeQuadInOut } from 'd3-ease';
import { useAnalyticsContext } from '../AnalyticsProvider/AnalyticsProvider';
import { Action, Category, Name } from '../../types/analytics';

// split the play button into two halves
// const playPathTop = 'M 1.5,0.88 L 2.94,0 L 15.5,6.45 L 15.5,7.45 L 1.5,7.45 Z';
// const playPathBottom = 'M 15.5,7.45 L 15.5,8.45 L 2.94,15 L 1.5,14.12 L 1.5,7.45 Z';

// triangle pointed downwards
// const playPathTopDown = 'M 14,0 L 15.5,0.88 L 8.75,14 L7.75,14  L 7.75,0Z';
// const playPathBottomDown = 'M7.75,14 L 6.75,14 L 0,0.88 L 1.5,0 L 7.75,0Z';

// needs 6 points to match play. adjusting the final point affects the animation
// const pausePathLeft = 'M 5.5,15 L 2.5,15 L 2.5,0 L 5.5,0 L 5.5,0 Z';
// const pausePathRight = 'M 10.5,0 L 13.5,0 L 13.5,15 L 10.5,15 L 10.5,0 Z';

// const playPath = `${playPathTop} ${playPathBottom}`;
// const playPathDown = `${playPathTopDown} ${playPathBottomDown}`;
// const pausePath = `${pausePathRight} ${pausePathLeft} `;
// rotate down, then split into pause
// const interpolator1 = interpolateString(playPath, playPathDown);
// const interpolator2 = interpolateString(playPathDown, pausePath);

// full triangle, morphs into left button (note this has been adjusted to be 16px high)
const playPathFull =
  'M 1.5,0.88 L 2.94,0 L 15.5,7.05 L 15.5,9.05 L 2.94,16 L 1.5,15.12 Z';
// morphs into right pause button
const playPathBar = 'M 10.5,6 L 15.5,8 L 15.5,8 L 10.5,10 L 2.5,14 L 2.5,2 Z';
const pausePathLeft = 'M 2.5,0 L2.5,0 L 5.5,0 L 5.5,16 L 2.5,16 L 2.5,16 Z';
const pausePathRight =
  'M 10.5,0  L 13.5,0 L 13.5,16 L 10.5,16 L10.5,10 L10.5,6 Z';
const playPath = `${playPathFull} ${playPathBar}`;
const pausePath = `${pausePathLeft} ${pausePathRight}`;

const interpolator = interpolateString(playPath, pausePath);

const MorphingPlayPauseIcon = ({
  isPlaying,
  width,
  className,
}: {
  isPlaying: boolean;
  width: number;
  className?: string | undefined;
}) => {
  const duration = 300;
  const [t, setT] = React.useState<number>(isPlaying ? 1 : 0);
  const [prevIsPlaying, setPrevIsPlaying] = React.useState(isPlaying);
  const [playingHasChanged, setPlayingHasChanged] = React.useState(false);

  // keep track of previous played value to know if we need to start an animation
  // keep track of if playing has changed at all to know if the button has
  // been clicked (this prevents an animation on mount)
  React.useEffect(() => {
    if (prevIsPlaying !== isPlaying) {
      setPlayingHasChanged(true);
    }
    setPrevIsPlaying(isPlaying);
  }, [isPlaying, prevIsPlaying]);

  const animateButtonChange = prevIsPlaying !== isPlaying;

  React.useEffect(() => {
    let canceling = false;

    // skip if the button has never been clicked (do not animate on mount)
    if (!playingHasChanged) {
      return;
    }

    function tick() {
      const now = Date.now();
      const elapsed = now - startTime;
      const absT = Math.min(1, Math.max(0, elapsed / duration));
      // change direction based on isPlaying
      const newT = isPlaying ? absT : 1 - absT;
      setT(newT);
      if (absT < 1 && !canceling) {
        // loop until 1
        raf = requestAnimationFrame(tick);
      }
    }

    let startTime = Date.now();
    // allow resuming from current animation:
    if (t !== 0 && t !== 1) {
      startTime -= isPlaying ? t * duration : (1 - t) * duration;
    }

    let raf = requestAnimationFrame(tick);

    return () => {
      canceling = true;
      cancelAnimationFrame(raf);
    };
    // TODO: this should include t, but some refactoring needed to make it work
    // eslint-disable-next-line
  }, [animateButtonChange, isPlaying, playingHasChanged]);

  const path = React.useMemo(() => {
    const tEase = easeQuadInOut(t);
    return interpolator(tEase);
  }, [t]);

  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={width}
      className={className}
      data-testid={isPlaying ? 'pause-icon' : 'play-icon'}
    >
      <path d={path} fill="white" />
    </svg>
  );
};

interface Props {
  audioUrl: string;
}
const PlayPauseButton = ({ audioUrl, ...other }: Props) => {
  const {
    src,
    onPause,
    isPlaying,
    isLoading,
    onPlay,
    onChangeSound,
    isLoaded,
    audioError,
    setCurrentSrc,
  } = React.useContext(GlobalAudioContext);

  React.useEffect(() => {
    // Set src of audio in context on page load.
    // This allows for src to be set on audio when audio play is requested.
    const fullAudioUrl =
      audioUrl && audioUrl[0] === '/'
        ? `${window.location.origin}${audioUrl}`
        : audioUrl;
    setCurrentSrc(fullAudioUrl);
  });

  const handlePlay = () => {
    const fullAudioUrl = getFullAudioUrl(audioUrl);
    if (src !== fullAudioUrl) {
      onChangeSound(fullAudioUrl);
    } else if (audioError) {
      // in the case of an audio error, avoid using the browser's cached version of the audio file
      // https://stackoverflow.com/questions/25821915/how-to-force-the-html5-audio-tag-to-reload-a-changing-file
      onChangeSound(getFullAudioUrl(audioUrl, true));
    }
    onPlay();
  };
  const { analyticsEvent } = useAnalyticsContext();
  const handleTogglePlay = () => {
    analyticsEvent({
      category: Category.Embed,
      action: Action.Play,
      name: Name.PlayPauseButton,
    });

    if (!isLoaded) {
      return handlePlay();
    } else if (isPlaying) {
      return onPause();
    } else {
      return handlePlay();
    }
  };

  const buttonClass = audioError
    ? `${styles.button} ${styles.error}`
    : styles.button;
  return (
    <button onClick={handleTogglePlay} className={buttonClass} {...other}>
      {isLoaded && isLoading && (
        <SpinnerIcon width={40} strokeWidth={1} className={styles.loading} />
      )}
      {audioError ? (
        <ExclamationIcon width={8} />
      ) : (
        <MorphingPlayPauseIcon isPlaying={isPlaying} width={16} />
      )}
    </button>
  );
};

export default PlayPauseButton;
