import React from 'react';

import CloseButton from '../CloseButton/CloseButton';
import CopyControl from '../CopyControl/CopyControl';

import styles from './ShareView.module.scss';

interface Props {
  highlightId: number;
  onExit: () => void;
}
const ShareView = ({ highlightId, onExit }: Props) => {
  const defaultEmbedCode = `<iframe src="${window.location.href}" width="100%" height="225" scrolling="no" frameborder="no" style="max-width: 570px; margin: 0 auto; display: block"></iframe>`;

  // This will return fora-dev.io url when developing locally and fora.io when deployed to either dev or prod
  let highlightBaseUrl = 'https://fora.io';
  if (process.env.NODE_ENV === 'development') {
    highlightBaseUrl = 'https://fora-dev.io';
  }
  const defaultHighlightPublicLink = `${highlightBaseUrl}/highlight/${highlightId}`;

  return (
    <div className={styles.container}>
      <CloseButton
        className={styles.exit}
        onClick={onExit}
        data-testid="exit"
      />
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <h3 className={styles.header}>Share this highlight</h3>
          <div className={styles.formGroup}>
            <p className={styles.subtitle}>Link to this highlight:</p>
            <CopyControl defaultText={defaultHighlightPublicLink} />
          </div>
          <div className={styles.formGroup}>
            <p className={styles.subtitle}>
              Add this highlight to your site by copying the following code:
            </p>
            <CopyControl defaultText={defaultEmbedCode} />
          </div>
        </div>
      </div>
      {/* TODO: uncomment once we have a link to a terms of service*/}
      {/* By using this embed, you agree to our{' '}
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use.
            </a> */}
    </div>
  );
};

export default ShareView;
