import React from 'react';
import styles from './SpinnerIcon.module.scss';

interface Props {
  width?: number;
  className?: string;
  strokeWidth?: number;
}
const SpinnerIcon = ({ width = 40, className, strokeWidth = 2 }: Props) => {
  const r = 12 - strokeWidth; // 12 - 2 for the width of the stroke
  const circumference = 2 * Math.PI * r;
  const opening = 0.7;
  const dashArray = `${circumference * (1 - opening)} ${circumference *
    opening}`;

  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={width}
      className={`${className ? className : ''} ${styles.isSpinning}`}
      data-testid="audio-loading"
    >
      <g transform="translate(12 12)">
        <circle
          r={r}
          className={styles.back}
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          r={r}
          className={styles.front}
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          fill="none"
        />
      </g>
    </svg>
  );
};

export default SpinnerIcon;
