import { get } from './helpers';
import { APIResponse } from '../types/core';
import { Conversation } from '../types/conversation';

function transformConversation(conversation: Conversation): Conversation {
  conversation.time = new Date((conversation as any).start_time);
  (conversation as any).start_time = new Date((conversation as any).start_time);
  (conversation as any).end_time = new Date((conversation as any).end_time);

  return conversation;
}

function transformResponse(data: APIResponse): APIResponse {
  const { conversation } = data;
  transformConversation(conversation);

  return data;
}

export function getHighlight(
  hid: number,
  secret: string | null
): Promise<APIResponse> {
  return get(`/highlight/${hid}`, secret ? { secret: secret } : undefined)
    .then((results) => results.data)
    .then(transformResponse);
}

export function getFullAudioUrl(audioUrl: string, bustCache?: boolean): string {
  let fullAudioUrl = new URL(
    audioUrl && audioUrl[0] === '/'
      ? `${window.location.origin}${audioUrl}`
      : audioUrl
  );
  if (bustCache) {
    fullAudioUrl.searchParams.set('cache', new Date().getTime().toString());
  }
  return fullAudioUrl.toString();
}
