import React from 'react';

import styles from './StylelessButton.module.scss';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void | undefined;
  children?: React.ReactNode;
  className?: string;
}

const StylelessButton = ({ onClick, children, className, ...other }: Props) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.button} ${className ? className : ''}`}
      {...other}
    >
      {children}
    </button>
  );
};

export default StylelessButton;
